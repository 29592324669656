import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-98fd115c"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "content"
};
import { onMounted, reactive } from 'vue';
import { useRoute } from 'vue-router';
export default {
  setup(__props) {
    const route = useRoute();
    const state = reactive({
      // 1 PC官网在线项目 2 众筹页編辑推荐 3 更新短信推广-兑换服务 4 PUSH推广-兑换服务  5 PC官网首页banner 6 搜索关键词 7 众筹页Banner 8 首页Banner 9 首页feed流
      demo_list: ['https://p6.moimg.net/mdwxapp/promotion_demo_8.png', 'https://p6.moimg.net/mdwxapp/promotion_demo_9.png', 'https://p6.moimg.net/mdwxapp/promotion_demo_7.png', 'https://p6.moimg.net/mdwxapp/promotion_demo_2.png', 'https://p6.moimg.net/mdwxapp/promotion_demo_6.png', 'https://p6.moimg.net/mdwxapp/promotion_demo_5.png', 'https://p6.moimg.net/mdwxapp/promotion_demo_1.png']
    });
    onMounted(() => {
      document.title = '案例说明';
      console.log('route------', route.query, route.params);
      if (route.query.exchange_id && route.query.exchange_id > 0) {
        state.demo_list = [`https://p6.moimg.net/mdwxapp/promotion_demo_${route.query.exchange_id}.png`];
      }
    });
    return (_ctx, _cache) => {
      const _component_van_image = _resolveComponent("van-image");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(state).demo_list, (url, index) => {
        return _openBlock(), _createBlock(_component_van_image, {
          key: index,
          class: "image",
          width: "100%",
          height: "auto",
          "lazy-load": "",
          src: _ctx.$imgUrlFilter(url, 750)
        }, null, 8, ["src"]);
      }), 128))]);
    };
  }
};